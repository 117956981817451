import { useCallback } from "react";
import { OperatorType } from "../../types/CalculatorTypes";

interface CalculatorScreenParameters {
  calculation: string;
  result: string;
  error: boolean;
  historyVisible: boolean,
  showHistory: any;
}

function CalculatorScreen({ calculation, result, error, historyVisible, showHistory }: CalculatorScreenParameters) {

  const formatCalculationCallback = useCallback(
    () => {
      return calculation
        .replace(OperatorType.Multiply, '×')
        .replace(OperatorType.Divide, '÷')
        .replace('.', ',')
        .replace('sqrt', '√')
    },
    [calculation],
  );

  const formatResultCallback = useCallback(
    () => {
      return result
        .replace('.', ',')
    },
    [result],
  );

  const fontSizeCallback = useCallback(
    () => {
      let str = result !== '' || error ? result : calculation

      if (str.length > 12) {
        return 'md'
      } else if (str.length > 8) {
        return 'lg'
      }

      return 'default';
    },
    [result, calculation, error],
  );

  return (
    <div className={`screen ${result !== '' || error ? '' : 'extanded'} size-${fontSizeCallback()}`}>
      <button aria-label="History" className={`history-button ${historyVisible ? 'opened' : ''}`} onClick={showHistory}>
        <svg xmlns="http://www.w3.org/2000/svg" className={historyVisible ? '' : 'visible'} viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" className={historyVisible ? 'visible' : ''} viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
        </svg>
      </button>
      <p className="calculation">{formatCalculationCallback()}</p>
      <p className="result" >{error ? 'ERROR' : formatResultCallback()}</p>
    </div>
  );
}

export default CalculatorScreen;
