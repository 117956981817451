import { OperationType, OperatorType } from "../types/CalculatorTypes"
import { isNumber, isOperator } from "./utils"

export default class CalculationHelper {
  private calculation: string
  private result: string
  private error: boolean
  private previousResultComputed: string
  private lastOperation: LastOperation | null

  constructor() {
    this.calculation = ''
    this.result = ''
    this.error = false
    this.previousResultComputed = ''
    this.lastOperation = null
  }

  updateCalculation(e: string, lastCalculationDone: boolean = false): this {
    if (lastCalculationDone) {
      this.lastOperation = null
      if (false === isOperator(e)) {
        this.calculation = e
      } else {
        this.calculation = `${this.result}${e}`
        this.lastOperation = { type: OperationType.Operator, value: '', operator: e as OperatorType }
      }
      this.previousResultComputed = ''
      this.result = ''
      return this
    }

    const lastChar = this.calculation.slice(this.calculation.length - 1)
    if (isOperator(lastChar) && isOperator(e)) {
      this.calculation = this.calculation.slice(0, -1) + e
      return this
    }

    let c = ''
    if (false === this.error) {
      c = `${this.calculation}${e}`
    } else {
      this.error = false
      c = e
    }

    if (this.previousResultComputed !== '') {
      const values = Object.values(OperatorType);
      if (values.includes(e as OperatorType)) {
        c = this.previousResultComputed + e
        this.previousResultComputed = ''
      }
    }

    if (this.lastOperation && this.lastOperation.type === OperationType.Operator && this.lastOperation.operator) {
      let operator = this.lastOperation.operator
      if (false === isNumber(e, true)) {
        operator = e as OperatorType;
      }
      const newValue = c.split(operator ? operator : '')[c.split(operator ? operator : '').length - 1]
      this.lastOperation = {
        type: OperationType.Operator,
        value: newValue ?? this.lastOperation.value,
        operator: operator
      }
    } else if (isNumber(e)) {
      this.lastOperation = { type: OperationType.Operator, value: e, operator: this.lastOperation?.operator }
    } else {
      this.lastOperation = { type: OperationType.Operator, value: '', operator: e as OperatorType }
    }

    this.calculation = c

    return this
  }


  //////////////////////////////////
  // GETTERS AND SETTERS          //
  //////////////////////////////////

  getCalculation(): string {
    return this.calculation;
  }

  setCalculation(calculation: string): this {
    this.calculation = calculation

    return this
  }

  getResult(): string {
    return this.result
  }

  setResult(result: string): this {
    this.result = result

    return this
  }

  getError(): boolean {
    return this.error
  }

  setError(error: boolean): this {
    this.error = error

    return this
  }

  getPreviousResultComputed(): string {
    return this.previousResultComputed
  }

  setPreviousResultComputed(previousResultComputed: string): this {
    this.previousResultComputed = previousResultComputed

    return this
  }

  getLastOperation(): LastOperation | null {
    return this.lastOperation
  }

  setLastOperation(lastOperation: LastOperation | null): this {
    this.lastOperation = lastOperation

    return this
  }
}