import { Menu } from '@headlessui/react'

interface UserDropdownParameter {
    user: any,
    logout: any,
}

function UserDropdown({ user, logout }: UserDropdownParameter) {

    return (
        <div className="user-info">
            <Menu>
                <Menu.Button aria-label="User info">
                    <img className="avatar" src={user.picture} alt={user.name} />
                </Menu.Button>
                <Menu.Items className="items">
                    <Menu.Item>
                        {({ active }) => (
                            <p className="item details">
                                <span className="username">{user.name}</span>
                                <span className="email">{user.email}</span>
                            </p>
                        )}
                    </Menu.Item>
                    <hr />
                    <Menu.Item>
                        {({ active }) => (
                            <button className="item logout" onClick={() => logout({ returnTo: window.location.origin })}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd" />
                                </svg>
                                <span>Log out</span>
                            </button>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Menu>
        </div>
    )
}

export default UserDropdown
