import { OperatorType } from "../types/CalculatorTypes";

export function isNumber(value: string | number, accepteCommas: boolean = false): boolean {
  if (accepteCommas && (value === '.' || value === ',')) {
    return true
  }
  return ((value != null) &&
    (value !== '') &&
    !isNaN(Number(value.toString())));
}

export function isOperator(e: string): boolean {
  return Object.values(OperatorType).includes(e as OperatorType)
}