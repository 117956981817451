import { Menu } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { useLocalstorageState } from 'rooks';

function ThemeDropdown() {
    enum ThemeType {
        Default = 'default',
        Pink = 'pink',
        Retro = 'retro',
        Glass = 'glass',
        Inverted = 'inverted',
    }

    const [lastTheme, setLastTheme] = useState(ThemeType.Default)
    const [currentTheme, setCurrentTheme] = useLocalstorageState('theme', ThemeType.Default);

    useEffect(() => {
        const element = document.getElementById('calculator')
        element?.classList.add(`theme-${currentTheme}`)
    }, [currentTheme])

    function addThemeToDom(theme: ThemeType) {
        const element = document.getElementById('calculator')
        element?.classList.add(`theme-${theme}`)
    }

    function resetTheme() {
        const element = document.getElementById('calculator')

        for (let themeType in ThemeType) {
            element?.classList.remove(`theme-${themeType.toLowerCase()}`)
        }
    }

    function selectTheme(theme: ThemeType) {
        resetTheme()
        setCurrentTheme(theme)
        addThemeToDom(theme)
        setLastTheme(theme)
        if (currentTheme !== theme) {
            localStorage.setItem('theme', theme)
        }
    }

    function previewTheme(theme: ThemeType) {
        resetTheme()
        document.getElementById('calculator')?.classList.add(`theme-${theme}`)
    }

    function resetLastTheme() {
        resetTheme()
        addThemeToDom(lastTheme)
    }

    return (
        <div className="theme-select">
            <Menu>
                <Menu.Button aria-label="Theme">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
                    </svg>
                </Menu.Button>
                <Menu.Items className="items">
                    <Menu.Item>
                        {({ active }) => (
                            <button onMouseEnter={() => previewTheme(ThemeType.Default)} onMouseLeave={() => resetLastTheme()} onClick={() => selectTheme(ThemeType.Default)} className={`${active && 'active'}`}>
                                <span className="color-icon border-primary bg-primary-light"></span>
                                <span>Default</span>
                            </button>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <button onMouseEnter={() => previewTheme(ThemeType.Pink)} onMouseLeave={() => resetLastTheme()} onClick={() => selectTheme(ThemeType.Pink)} className={`${active && 'active'}`}>
                                <span className="color-icon border-pink-500 bg-pink-200"></span>
                                <span>Pink</span>
                            </button>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <button onMouseEnter={() => previewTheme(ThemeType.Retro)} onMouseLeave={() => resetLastTheme()} onClick={() => selectTheme(ThemeType.Retro)} className={`${active && 'active'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
                                </svg>
                                <span>Retro</span>
                            </button>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <button onMouseEnter={() => previewTheme(ThemeType.Glass)} onMouseLeave={() => resetLastTheme()} onClick={() => selectTheme(ThemeType.Glass)} className={`${active && 'active'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z" clipRule="evenodd" />
                                </svg>
                                <span>Glass</span>
                            </button>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <button onMouseEnter={() => previewTheme(ThemeType.Inverted)} onMouseLeave={() => resetLastTheme()} onClick={() => selectTheme(ThemeType.Inverted)} className={`${active && 'active'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
                                </svg>
                                <span>Inverted</span>
                            </button>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Menu>
        </div>
    )
}

export default ThemeDropdown
