import { useEffect, useState } from "react";
import { useLocalstorageState } from "rooks";
import ThemeDropdown from "./ThemeDropdown";
import { useAuth0 } from "@auth0/auth0-react";
import UserDropdown from "./UserDropdown";

function LayoutNavbar() {

  const { user, isAuthenticated, logout, loginWithRedirect } = useAuth0();

  const [darkTheme, setDarkTheme] = useLocalstorageState('dark-theme', false);
  const [showShadow, setShowShadow] = useState((window.pageYOffset || document.documentElement.scrollTop) > 30)

  useEffect(() => {
    if (darkTheme) {
      document.querySelector('html')?.classList.add('dark')
    } else {
      document.querySelector('html')?.classList.remove('dark')
    }
    localStorage.setItem('dark-theme', darkTheme.toString())
  }, [darkTheme])

  window.addEventListener('scroll', () => {
    const top = window.pageYOffset || document.documentElement.scrollTop
    setShowShadow(top > 30)
  });

  return (
    <nav id="navbar" className={showShadow ? 'shadow-lg' : ''}>
      <span className="logo">
        stoïk
      </span>
      <div className="actions">
        <ThemeDropdown />
        <button aria-label="Theme" className={`action ${darkTheme ? 'hidden' : ''}`} onClick={() => setDarkTheme(!darkTheme)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </button>
        <button aria-label="Dark theme" className={`action ${darkTheme ? '' : 'hidden'}`} onClick={() => setDarkTheme(!darkTheme)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
          </svg>
        </button>
        {(isAuthenticated && user) && (
          <UserDropdown user={user} logout={logout} />
        )
        } {false === (isAuthenticated && user) && (
          <button aria-label="Login" onClick={() => loginWithRedirect()}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </button>
        )
        }

      </div>
    </nav>
  );
}

export default LayoutNavbar;
