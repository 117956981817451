export enum CalculatorType {
    Standard = 'standard',
    Scientific = 'scientific',
}

export enum OperationType {
    Operator = 'operator',
    Invert = 'invert',
    Basic = 'basic',
    Complex = 'complex',
}

export enum OperatorType {
    Add = '+',
    Substract = '-',
    Multiply = '*',
    Divide = '/',
    Pow = '^',
}