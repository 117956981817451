import { BaseSyntheticEvent, useEffect, useState } from "react";
import { useKeyBindings } from "rooks";

interface CalculatorButtonParameters {
  type?: string;
  value: string;
  label: string;
  action?: any;
  superscript?: string;
  subscript?: string;
}

function CalculatorButton({ type, value, label, action, superscript, subscript }: CalculatorButtonParameters) {

  const [pressed, setPressed] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setPressed(false)
    }, 150)
  }, [pressed])

  function handleClick(e: BaseSyntheticEvent): void {
    e.target.blur()
    action(value)
  }

  function handleKeyPress() {
    setPressed(true)
    action(value)
  }

  const obj: { [k: string]: any } = {};
  obj[value] = handleKeyPress;
  if (value === '.') {
    obj[','] = handleKeyPress
  } else if (value === '=') {
    obj['Enter'] = handleKeyPress
  }
  useKeyBindings(obj);

  return (
    <button className={`key ${type} ${pressed ? "pressed" : ""}`} onClick={(e) => handleClick(e)}>
      {label}
      {superscript !== null &&
        <sup>{superscript}</sup>
      }
      {subscript !== null &&
        <sub>{subscript}</sub>
      }
    </button>
  );
}

export default CalculatorButton;
