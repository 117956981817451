import { evaluate, format } from "mathjs"
import { OperationType } from "../types/CalculatorTypes"
import { isNumber } from "./utils"

export default class CalculatorHelper {
  private readonly PRECISION: number = 14

  public calculation: string
  private result: string
  private error: boolean
  private lastOperation: LastOperation | null

  constructor() {
    this.calculation = ''
    this.result = ''
    this.error = false
    this.lastOperation = null
  }

  calculate(): HistoryItem | null {
    if (this.calculation !== '') {
      try {
        const result = format(evaluate(this.calculation), { precision: this.PRECISION })
        this.result = result
        const historyItem: HistoryItem = { calculation: this.calculation, result }
        return historyItem
      } catch (e: any) {
        this.error = true
        return null
      }
    }

    this.result = ''

    return null
  }

  runCalculation(runLastCalculation: boolean = false): this {
    if (null === this.lastOperation) {
      try {
        if (this.calculation !== '') {
          const result = format(evaluate(this.calculation), { precision: this.PRECISION })
          this.result = result
        }
      } catch (e: any) {
        this.result = ''
        this.error = true
      }
    } else {
      let result = ''
      switch (this.lastOperation.type) {
        case OperationType.Invert: result = this.invert(); break
        case OperationType.Basic: result = this.basicFunction(this.lastOperation?.value); break
        case OperationType.Complex: result = this.complexFunction(this.lastOperation?.value); break
        default: result = this.calculatorOperation(this.lastOperation?.value, this.lastOperation); break
      }
      if (runLastCalculation) {
        this.calculation = result
      }
    }

    //this.calculate(this.calculation)

    return this
  }

  erase(): this {
    this.calculation = this.calculation.slice(0, -1)

    return this
  }


  //////////////////////////////////
  // OPERATION FUNCTIONS          //
  //////////////////////////////////

  oppose() {
    if (isNumber(this.result)) {
      this.calculation = `-${this.result}`
    } else {
      this.calculation = `-${this.calculation}`
    }

    return null;
  }

  invert(): string {
    let c = this.calculation
    if (isNumber(this.result)) {
      c = `1/${this.result}`
    } else {
      c = `1/${this.calculation}`
    }

    this.lastOperation = { type: OperationType.Invert, value: '' }

    return c
  }

  complexFunction(e: string): string {
    let c = this.calculation
    if (isNumber(this.result)) {
      c = `${e}(${this.result})`
    } else {
      c = `${e}(${this.calculation})`
    }

    this.lastOperation = { type: OperationType.Complex, value: e }

    return c
  }

  basicFunction(e: string): string {
    let c = this.calculation
    if (isNumber(this.result)) {
      c = `${this.result}${e}`
    } else {
      c = `${this.calculation}${e}`
    }

    this.lastOperation = { type: OperationType.Basic, value: e }

    return c
  }

  calculatorOperation(e: string, lastOperation: LastOperation): string {
    let c = this.calculation
    if (lastOperation.operator && this.result !== '') {
      if (isNumber(this.result)) {
        c = `${this.result}${lastOperation.operator}${lastOperation.value}`
      } else {
        c = `${this.calculation}${lastOperation.operator}${lastOperation.value}`
      }
    }

    this.lastOperation = { type: OperationType.Operator, value: e, operator: lastOperation.operator }

    return c
  }


  //////////////////////////////////
  // GETTERS AND SETTERS          //
  //////////////////////////////////

  getCalculation(): string {
    return this.calculation
  }

  setCalculation(calculation: string): this {
    this.calculation = calculation

    return this
  }

  getResult(): string {
    return this.result
  }

  setResult(result: string): this {
    this.result = result

    return this
  }

  getError(): boolean {
    return this.error
  }

  setError(error: boolean): this {
    this.error = error

    return this
  }

  getLastOperation(): LastOperation | null {
    return this.lastOperation
  }

  setLastOperation(lastOperation: LastOperation | null): this {
    this.lastOperation = lastOperation

    return this
  }
}