
function History({ history, visible, action }: HistoryParameters) {

    return (
        <div id="history" className={visible ? 'visible' : ''}>
            <ul className="items">
                {history.map((item: HistoryItem, index: number) => {
                    return (
                        <li className="item" onClick={() => action(item)} key={index}>
                            <p className="calculation">{item.calculation}</p>
                            <p className="result">{item.result}</p>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}

export default History;
