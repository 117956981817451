import { useEffect, useState } from 'react';
import CalculatorButton from './Button';
import CalculatorScreen from './Screen';
import { useKeyBindings } from 'rooks';
import History from '../History/History';
import { CalculatorType, OperatorType } from '../../types/CalculatorTypes';
import CalculatorHelper from '../../services/calculator';
import CalculationHelper from '../../services/calculation';

function Calculator() {

  let calculatorHelper = new CalculatorHelper()
  let calculationHelper = new CalculationHelper()


  //////////////////////////////////
  // REACT FUNCTIONS              //
  //////////////////////////////////

  const [calculatorType, setCalculatorType] = useState<CalculatorType>(CalculatorType.Standard);
  const [calculation, setCalculation] = useState<string>('');
  const [result, setResult] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [lastCalculationDone, setLastCalculationDone] = useState<boolean>(false);
  const [lastOperation, setLastOperation] = useState<LastOperation | null>(null)
  const [previousResultComputed, setPreviousResultComputed] = useState<string>('');
  const [historyVisible, setHistoryVisible] = useState<boolean>(false);
  const [history, setHistory] = useState<Array<HistoryItem>>([])
  const [memoryResult, setMemoryResult] = useState<string>('0')


  useEffect(() => {
    if (error) {
      setLastCalculationDone(true)
      setLastOperation(null)
      setResult('')
    }
  }, [error])

  useEffect(() => {
    calculatorHelper.setCalculation(calculation)
    calculatorHelper.setResult(result)
    calculatorHelper.setError(error)
    calculatorHelper.setLastOperation(lastOperation)

    calculationHelper.setCalculation(calculation)
    calculationHelper.setResult(result)
    calculationHelper.setError(error)
    calculationHelper.setPreviousResultComputed(previousResultComputed)
    calculationHelper.setLastOperation(lastOperation)
  })

  useEffect(() => {
    setError(false)
  }, [calculation])


  //////////////////////////////////
  // WINDOW EVENTS                //
  //////////////////////////////////

  window.addEventListener("orientationchange", function () {
    if (window.innerWidth < 650) {
      if (window.orientation === 0 || window.orientation === 180) {
        setCalculatorType(CalculatorType.Standard)
      } else {
        setCalculatorType(CalculatorType.Scientific)
      }
    }
  }, false);

  window.addEventListener("resize", function () {
    if (window.innerWidth > 1024) {
      setCalculatorType(CalculatorType.Standard)
    }
  }, false);


  //////////////////////////////////
  // SCREEN AND RESULT FUNCTIONS  //
  //////////////////////////////////

  function calculate() {
    const calculatorResult = calculatorHelper.calculate()
    setLastOperation(calculationHelper.getLastOperation())
    setError(calculatorHelper.getError())
    if (calculatorResult) {
      setResult(calculatorHelper.getResult())
      setPreviousResultComputed(calculatorHelper.getResult())
      setHistory([calculatorResult, ...history] as any)
      setLastCalculationDone(true)
    }
  }

  function updateCalculation(e: string) {
    calculationHelper.updateCalculation(e, lastCalculationDone)
    setResult(calculationHelper.getResult())
    setCalculation(calculationHelper.getCalculation())
    setError(calculationHelper.getError())
    setPreviousResultComputed(calculationHelper.getPreviousResultComputed())
    setLastOperation(calculationHelper.getLastOperation())
    setLastCalculationDone(false)
  }

  function runCalculation() {
    calculatorHelper.runCalculation(lastCalculationDone)
    calculate()
    setLastCalculationDone(true)
  }

  function erase() {
    setCalculation(calculatorHelper.erase().getCalculation())
    setResult('')
  }

  function reset() {
    setResult('')
    setCalculation('')
    setLastOperation(null)
  }


  //////////////////////////////////
  // CALCULATOR FUNCTIONS         //
  //////////////////////////////////

  function oppose() {
    calculatorHelper.setLastOperation(null)
    calculatorHelper.oppose()
    setLastOperation(null)
    //setCalculation(calculatorHelper.getCalculation())
    runCalculation()
  }

  function invert() {
    setCalculation(calculatorHelper.invert())
    setLastOperation(calculatorHelper.getLastOperation())
  }

  function complexFunction(e: string) {
    if (calculation !== '') {
      setCalculation(calculatorHelper.complexFunction(e))
      setLastOperation(calculatorHelper.getLastOperation())
    }
  }

  function basicFunction(e: string) {
    if (calculation !== '') {
      setCalculation(calculatorHelper.basicFunction(e))
      setLastOperation(calculatorHelper.getLastOperation())
    }
  }


  //////////////////////////////////
  // HISTORY AND MEMORY FUNCTIONS //
  //////////////////////////////////

  function setHistoryItem(item: HistoryItem) {
    setCalculation(item.calculation)
    setResult(item.result)
    setLastOperation(null)
  }

  function setMemory() {
    if (result !== '') {
      setMemoryResult(result)
    }
  }

  function resetMemory() {
    setMemoryResult('0')
  }

  function applyMemory() {
    updateCalculation(memoryResult)
  }

  function toggleHistory(e: any) {
    e.target.blur()
    setHistoryVisible(!historyVisible)
  }


  //////////////////////////////////
  // KEY BINDINGS                 //
  //////////////////////////////////

  useKeyBindings({ 'Backspace': () => erase() });


  //////////////////////////////////
  // TEMPLATE                     //
  //////////////////////////////////

  return (
    <div className="wrapper calculator-container" id="calculator">
      <h1 className="title">
        <span>stoïk</span> calculator
      </h1>
      <div className={`wrapper-content calculator ${calculatorType === CalculatorType.Scientific ? 'scientific' : ''}`}>
        <CalculatorScreen calculation={calculation} result={result} error={error} historyVisible={historyVisible} showHistory={toggleHistory} />
        <div className="keys">
          <History history={history} visible={historyVisible} action={setHistoryItem} />
          {calculatorType === CalculatorType.Scientific &&
            <>
              <CalculatorButton value="mc" label="mc" action={resetMemory} />
              <CalculatorButton value="(" label="(" action={updateCalculation} />
              <CalculatorButton value=")" label=")" action={updateCalculation} />
            </>
          }
          <CalculatorButton value="AC" label="AC" type="action" action={reset} />
          <CalculatorButton value="+/-" label="±" type="action" action={oppose} />
          <CalculatorButton value="%" label="%" type="action" action={updateCalculation} />
          <CalculatorButton value={OperatorType.Divide} label="÷" type="action" action={updateCalculation} />
          {calculatorType === CalculatorType.Scientific &&
            <>
              <CalculatorButton value="m+" label="m+" action={setMemory} />
              <CalculatorButton value="log10" label="log" subscript='10' action={complexFunction} />
              <CalculatorButton value="sqrt" label="√" action={complexFunction} />
            </>
          }
          <CalculatorButton value="7" label="7" action={updateCalculation} />
          <CalculatorButton value="8" label="8" action={updateCalculation} />
          <CalculatorButton value="9" label="9" action={updateCalculation} />
          <CalculatorButton value={OperatorType.Multiply} label="×" type="action" action={updateCalculation} />
          {calculatorType === CalculatorType.Scientific &&
            <>
              <CalculatorButton value="m-" label="m-" action={resetMemory} />
              <CalculatorButton value="1/x" label="1/x" type='diagonal-fractions' action={invert} />
              <CalculatorButton value="^2" label="x" superscript="2" action={basicFunction} />
            </>
          }
          <CalculatorButton value="4" label="4" action={updateCalculation} />
          <CalculatorButton value="5" label="5" action={updateCalculation} />
          <CalculatorButton value="6" label="6" action={updateCalculation} />
          <CalculatorButton value={OperatorType.Substract} label="-" type="action" action={updateCalculation} />
          {calculatorType === CalculatorType.Scientific &&
            <>
              <CalculatorButton value="mr" label="mr" type="row-span-2" action={applyMemory} />
              <CalculatorButton value="^" label="x" superscript="y" action={updateCalculation} />
              <CalculatorButton value="^3" label="x" superscript="3" action={basicFunction} />
            </>
          }
          <CalculatorButton value="1" label="1" action={updateCalculation} />
          <CalculatorButton value="2" label="2" action={updateCalculation} />
          <CalculatorButton value="3" label="3" action={updateCalculation} />
          <CalculatorButton value={OperatorType.Add} label="+" type="action" action={updateCalculation} />
          {calculatorType === CalculatorType.Scientific &&
            <>
              <CalculatorButton value="!" label="x!" action={basicFunction} />
              <CalculatorButton value="^10" label="x" superscript="10" action={basicFunction} />
            </>
          }
          <CalculatorButton value="0" label="0" type='' action={updateCalculation} />
          <CalculatorButton value="." label="." action={updateCalculation} />
          <div></div>
          <CalculatorButton value="=" label="=" type="primary" action={runCalculation} />
        </div>
      </div>
      <div className="actions">
        <button className={calculatorType === CalculatorType.Standard ? 'active' : ''} onClick={() => setCalculatorType(CalculatorType.Standard)}>Standard</button>
        <button className={calculatorType === CalculatorType.Scientific ? 'active' : ''} onClick={() => setCalculatorType(CalculatorType.Scientific)}>Scientific</button>
      </div>
      <a href='https://martinmarx.fr' target='_blank' rel='noopener noreferrer' className="copyright">
        <span>by</span> Martin MARX
      </a>
      <div className="illustration-top"></div>
      <div className="illustration-bottom"></div>
    </div>
  );
}

export default Calculator;
