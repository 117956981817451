import './App.scss';
import Calculator from './Components/Calculator/Calculator';
import LayoutNavbar from './Components/Layout/Navbar';

function App() {

  return (
    <div>
      <LayoutNavbar />
      <Calculator />
    </div>
  );
}

export default App;
